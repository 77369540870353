import 'typeface-lora'
import 'typeface-roboto-mono'
import 'typeface-titillium-web'
import { getFooterData, getHeaderData } from '../api'

import { Button } from '@/atoms'
import { default as normalizeFooterData } from '../models/Footer'
import { default as normalizeHeaderData } from '../models/Header'

export const getStaticProps = async () => {
  const [header, footer] = await Promise.all([getHeaderData(), getFooterData()])

  return {
    props: {
      title: '404',
      content: 'Pagina non trovata',
      buttonLabel: 'Torna alla Homepage',
      header: normalizeHeaderData(header),
      footer: normalizeFooterData(footer),
    },
  }
}

const mainClassName = 'error-pages'
export default function NotFound({
  title,
  buttonLabel,
  content,
  header,
  footer,
}) {
  return (
    <section className={`${mainClassName}_section`}>
      <div className={`${mainClassName}_section_container`}>
        <div className={`${mainClassName}_section_container_content`}>
          <h1 className={`${mainClassName}_section_container_content_title `}>
            {title}
          </h1>
          <h2 className={`${mainClassName}_section_container_content_text`}>
            {content}
          </h2>
          <Button
            href='/'
            className={`${mainClassName}_section_container_content_button`}
            internalUrl={true}
            isInternalPage={false}
            openInNewTab={false}
            trackEventArgs={{
              category: 'Button',
              action: 'Click',
              name: 'Error 404',
              value: buttonLabel,
            }}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </section>
  )
}
